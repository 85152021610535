// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";

//Components
import DashboardPage from "views/Dashboard/Dashboard.js";
import TicketStatusDev from "views/TicketStatusDev/TicektStatusDev";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: ConfirmationNumberSharpIcon,
    component: TicketStatusDev,
    layout: "/admin",
  },
];

export default dashboardRoutes;
