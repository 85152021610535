import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import { Domain } from "Domain";
import axios from "axios";

import PropTypes from "prop-types";

const styles = {
  cardCategoryRed: {
    color: "#ff0000",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function LoginPage({
  setLoggedin,
  setUserType,
  setUserdetails,
  setNotification,
  setNotification1,
}) {
  const [LoginView, setLoginView] = useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
  });
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  const [invalid, setInvalid] = React.useState(false);
  const [inv_fld, setInv_fld] = React.useState(false);

  const [logData, setLogData] = React.useState({
    Email: "",
    Password: "",
  });
  function HandleLogData(e) {
    const newData = { ...logData };
    newData[e.target.id] = e.target.value;
    setLogData(newData);
    console.log(newData);
  }
  function HandleLogin() {
    if (logData.Email != "" && logData.Password != "") {
      let formData = new FormData();
      formData.append("Email", logData.Email);
      formData.append("Password", logData.Password);
      console.log("formData", formData);
      axios({
        method: "post",
        url: Domain + "/LoginCheck.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Response:", response);
          if (response.data.success) {
            setLogData({
              Username: "",
              Password: "",
            });
            console.log("Logged in as", response.data.data.Usertype);
            setUserType(response.data.data.Usertype);
            setUserdetails(response.data.data);
            setInv_fld(false);
            setLoggedin(true);
            setNotification(true);
          } else {
            setInv_fld(true);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("error", response);
          setInv_fld(true);
          setLoggedin(undefined);
        });
    } else {
      setInv_fld(true);
      setLoggedin(undefined);
      // console.log(json.Data);
    }
  }
  function HandleSave() {
    if (data.Email != "" && data.Password != "" && data.FirstName != "") {
      let formData = new FormData();
      formData.append("Id", 0);
      formData.append("FirstName", data.FirstName + " " + data.LastName);
      formData.append("Email", data.Email);
      formData.append("Password", data.Password);
      console.log("formData", formData);
      axios({
        method: "post",
        url: Domain + "saveData.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Response:", response);
          if (response.data.success) {
            setData({
              Email: "",
              Password: "",
              FirstName: "",
              LastName: "",
            });
            // console.log("Logged in as", response.data.data.Usertype);
            // setUserType(response.data.data.Usertype);
            // setUserdetails(response.data.data);
            setInvalid(false);
            setLoginView(false);
            //setLoggedin(true);
            setNotification1(true);
          } else {
            setInvalid(true);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("error", response);
          setInvalid(true);
          setLoggedin(undefined);
        });
    } else {
      setInvalid(true);
      setLoggedin(undefined);
      // console.log(json.Data);
    }
  }

  return (
    <>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            {LoginView ? (
              <>
                <form>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>
                      Sign Up for the first time
                    </h4>
                    <p className={classes.cardCategoryWhite}>
                      Enter the details below
                    </p>
                  </CardHeader>

                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={10}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              error={invalid}
                              onChange={(e) => HandleData(e)}
                              value={data.FirstName}
                              labelText="First Name"
                              id="FirstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              error={invalid}
                              onChange={(e) => HandleData(e)}
                              value={data.LastName}
                              labelText="Last Name"
                              id="LastName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              error={invalid}
                              onChange={(e) => HandleData(e)}
                              value={data.Email}
                              labelText="Email"
                              id="Email"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              error={invalid}
                              type="password"
                              onChange={(e) => HandleData(e)}
                              value={data.Password}
                              labelText="Password"
                              id="Password"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />

                            {invalid ? (
                              <p className={classes.cardCategoryRed}>
                                Fill all mandatory details
                              </p>
                            ) : (
                              <div></div>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <Button fullWidth color="info" onClick={HandleSave}>
                              Sign Up
                            </Button>
                            Already Have an Account?{" "}
                            <a onClick={() => setLoginView(false)}>
                              Login Here
                            </a>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter></CardFooter>
                </form>
              </>
            ) : (
              <>
                <form>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>Admin Login</h4>
                    <p className={classes.cardCategoryWhite}>
                      Enter the login Credentails below
                    </p>
                  </CardHeader>

                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={8}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              error={inv_fld}
                              onChange={(e) => HandleLogData(e)}
                              value={logData.Email}
                              labelText="User Name or Email"
                              id="Email"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              type="password"
                              error={inv_fld}
                              onChange={(e) => HandleLogData(e)}
                              value={logData.Password}
                              labelText="Password"
                              id="Password"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />

                            {invalid ? (
                              <p className={classes.cardCategoryRed}>
                                Invalid Admin Credentials
                              </p>
                            ) : (
                              <div></div>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <Button
                              fullWidth
                              onClick={HandleLogin}
                              color="info"
                            >
                              Login
                            </Button>
                            Not Registered?{" "}
                            <a onClick={() => setLoginView(true)}>
                              Click Here to Signup
                            </a>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter></CardFooter>
                </form>
              </>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
LoginPage.propTypes = {
  setLoggedin: PropTypes.func,
  setNotification: PropTypes.func,
  setNotification1: PropTypes.func,
  setUserType: PropTypes.any,
  setUserdetails: PropTypes.any,
};
