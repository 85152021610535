import React, { useState } from "react";
import axios from "axios";
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
// import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
// import Update from "@material-ui/icons/Update";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import SearchBar from "material-ui-search-bar";
import { CloudDownload } from "@material-ui/icons";
// import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  //To get Raised Tickets list from database

  const [searchItem, setSearchItem] = useState("");
  const [data, setData] = useState([]);

  function HandleSearch(searchKey) {
    setSearchItem(searchKey);
    if (searchKey.indexOf(",") > -1) {
      searchKey.split(",").map((d) => {
        console.log(":::", d);
      });
    }
    console.log("SearchKey1", searchKey.split(",")[0]);
    console.log("SearchKey2", searchKey.split(",")[1]);
    console.log("SearchKey3", searchKey.split(",")[2]);
    let formData = new FormData();
    formData.append("SearchKey1", searchKey.split(",")[0]);
    formData.append("SearchKey2", searchKey.split(",")[1]);
    formData.append("SearchKey3", searchKey.split(",")[2]);
    axios({
      method: "post",
      url: Domain + "/SearchDrive.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response.data);
        if (response.data.success) {
          console.log("Success");
          setData(response.data.data);
        } else {
          console.log("Failed");
          setData([]);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br />
          <SearchBar
            placeholder="Search in Drive"
            value={searchItem}
            onChange={(value) => {
              HandleSearch(value);
            }}
            onRequestSearch={() => console.log("onRequestSearch")}
          />
          <GridContainer>
            {data.map((item) => (
              <GridItem xs={12} sm={6} md={3} key={item.Id}>
                <Card>
                  <CardHeader color="warning" icon>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.cardCategory}>
                          <img src={item.Description} alt="" />{" "}
                          <b>{item.Title.slice(0, 30)}</b>
                        </p>
                        <p className={classes.cardTitle}>
                          {item.FileSize != "" ? item.FileSize / 1000 : 0} kb
                        </p>
                        <p className={classes.cardTitle}>{item.FileType} </p>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      {/* <a
                        href={item.Description}
                        target="_top"
                        style={{ textTransform: "none" }}
                      >
                        {" "}
                        Download file
                      </a> */}
                    </div>
                    <div className={classes.stats}>
                      <a
                        href={item.Url}
                        target="_top"
                        startIcon={<CloudDownload />}
                        style={{ textTransform: "none" }}
                        simple
                      >
                        {" "}
                        Open file
                      </a>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
